import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'

import Page from '../../components/Page'
import Schema from '../../components/Schema'
import HeaderInfo from './../../components/articles/HeaderInfo'
import ShareButtons from '../../components/articles/ShareButtons'
import Content from '../../components/articles/Content'
import EditorialArticleLink from '../../components/articles/Listings/EditorialArticleLink'
import Newsletter from '../../components/Newsletter'

export default function InsiderDiaryTemplate({ data }) {
  const { article, site, relatedArticles } = data
  const shareUrl = encodeURI(
    `${site.siteMetadata.siteUrl}${article.categoryLink}/${article.slug}`
  )

  const breadcrumbs = [
    {
      name: 'Editorial',
      uri: '/editorial',
    },
    {
      name: article.category,
      uri: article.categoryLink,
    },
    {
      name: article.title,
      uri: `${article.categoryLink}/${article.slug}`,
    },
  ]

  return (
    <Page
      className="p-newsArticle p-articleEditorials has-fingerPrints"
      datoIndex
    >
      <HelmetDatoCms seo={article.seoMetaTags} />
      <Schema breadcrumbs={breadcrumbs} />
      {article.canonicalUrl && (
        <Helmet
          link={[
            {
              rel: 'canonical',
              key: article.canonicalUrl,
              href: article.canonicalUrl,
            },
          ]}
        />
      )}

      <article
        id={article.id}
        className="c-article"
        style={{
          background: 'linear-gradient(180deg, #fff 400px, #f8f8f8 400px)',
        }}
      >
        <div className="container py-5">
          <header className="c-articleFull">
            <HeaderInfo
              date={article.pubDateTime}
              category={article.category}
              categoryLink={article.categoryLink}
            />

            <div className="c-articleFull__hero">
              <div className="c-editorialHeroOverlay">
                <div className="c-editorialHeroOverlay__header">
                  <strong>{article.category.toUpperCase()}</strong>
                </div>
                <h1 className="c-editorialHeroOverlay__title">
                  {article.title}
                </h1>
              </div>
              <Img
                fluid={article.heroImage.fluid}
                title={article.heroImage.title}
                alt={article.heroImage.alt}
                loading="eager"
                className="c-articleFull__heroImage img-fluid"
              />
            </div>
          </header>

          {/* <!-- Intro --> */}
          <section>
            <div className="row">
              <div className="c-socialAuthor col-lg-3">
                <div>
                  <ShareButtons shareUrl={shareUrl} />
                </div>
                <div className="c-articleAuthor">
                  <div>
                    <Img
                      fixed={article.author.avatar.fixed}
                      title={article.author.avatar.title}
                      alt={article.author.avatar.alt}
                      className="c-articleAuthor__image img-fluid"
                    />
                  </div>
                  <h4 className="c-articleAuthor__name">
                    {article.author.name}
                  </h4>
                  <small className="c-articleAuthor__bio">
                    {article.author.bio}
                  </small>
                </div>
              </div>
              <div className="col-12 col-lg-9">
                {article.leadIn && (
                  <div className="row">
                    <div className="col-12">
                      <h2 className="c-articleLeadIn">{article.leadIn}</h2>
                      <hr />
                    </div>
                  </div>
                )}

                {/* <!-- Body --> */}
                <div className="row">
                  <div className="col-12">
                    <Content content={article.content} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="c-articleAuthor d-lg-none">
            <div className="mt-3">
              <Img
                fixed={article.author.avatar.fixed}
                title={article.author.avatar.title}
                alt={article.author.avatar.alt}
                className="c-articleAuthor__image img-fluid"
              />
            </div>
            <h4 className="c-articleAuthor__name">{article.author.name}</h4>
            <small className="c-articleAuthor__bio">{article.author.bio}</small>
          </div>
        </div>
      </article>

      {/* <!-- Related --> */}
      <section
        id="latest-editorial"
        className="c-latestEditorial"
        data-datocms-noindex
      >
        <div className="container">
          <div className="c-latestEditorial__inner">
            <div className="row">
              <div className="c-latestEditorial__header col-12">
                <h3 className="c-latestEditorial__header__title">
                  Related Editorial
                </h3>
                <Link to="/editorial" className="c-postReturnLink">
                  <img src="/icons/arrow-right.svg" className="c-icon mr-2" />
                  <span className="c-postReturnLink__text">All Editorial</span>
                </Link>
              </div>
            </div>

            <div className="row">
              {relatedArticles.edges.map(({ node }) => (
                <div key={node.slug} className="col-6 col-lg-4">
                  <EditorialArticleLink article={node} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export const pageQuery = graphql`
  query InsiderDiaryQuery($slug: String!) {
    article: datoCmsEditorialArticle(slug: { eq: $slug }) {
      title
      slug
      canonicalUrl
      category
      categoryLink
      pubDateTime
      heroImage {
        fluid(imgixParams: { w: "1110", fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      author {
        name
        bio
        avatar {
          fixed(
            width: 165
            height: 190
            imgixParams: { fm: "jpg", auto: "compress", fit: "crop" }
          ) {
            ...GatsbyDatoCmsFixed
          }
        }
      }
      leadIn
      content {
        ...ArticleContentFragment
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    relatedArticles: allDatoCmsEditorialArticle(
      filter: { slug: { ne: $slug } }
      sort: { fields: displayDate, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          relPath
          category
          categoryLink
          pubDateTime
          heroImage {
            url
            alt
            title
            customData
          }
        }
      }
    }
  }
`
